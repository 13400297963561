<template>
<div class="use_full_info">
  <HeaderWrp/>
  <Breadcrumbs>
    <router-link :to="{ name: 'Home', params: {'lang': lang}}">{{ $t('usefulInfo.home') }}</router-link>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arr">
          <path d="M10.9605 8.14506C11.0423 8.06543 11.0423 7.93409 10.9605 7.85446L5.32138 2.36015C5.2426 2.28339 5.11702 2.28339 5.03824 2.36014C4.95651 2.43977 4.95651 2.57112 5.03824 2.65075L10.6774 8.14506C10.7562 8.22181 10.8818 8.22181 10.9605 8.14506Z" fill="#000001"/>
          <path d="M5.03824 13.6398C4.95651 13.5602 4.95651 13.4289 5.03824 13.3492L10.6791 7.85331C10.7578 7.77656 10.8834 7.77656 10.9622 7.85331C11.0439 7.93294 11.0439 8.06429 10.9622 8.14391L5.32137 13.6398C5.2426 13.7166 5.11701 13.7166 5.03824 13.6398Z" fill="#000001"/>
        </svg>
      
        <router-link :to="{ name: 'useful-info', params: {'lang': lang}}">{{ $t('usefulInfo.blog') }}</router-link>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arr">
          <path d="M10.9605 8.14506C11.0423 8.06543 11.0423 7.93409 10.9605 7.85446L5.32138 2.36015C5.2426 2.28339 5.11702 2.28339 5.03824 2.36014C4.95651 2.43977 4.95651 2.57112 5.03824 2.65075L10.6774 8.14506C10.7562 8.22181 10.8818 8.22181 10.9605 8.14506Z" fill="#000001"/>
          <path d="M5.03824 13.6398C4.95651 13.5602 4.95651 13.4289 5.03824 13.3492L10.6791 7.85331C10.7578 7.77656 10.8834 7.77656 10.9622 7.85331C11.0439 7.93294 11.0439 8.06429 10.9622 8.14391L5.32137 13.6398C5.2426 13.7166 5.11701 13.7166 5.03824 13.6398Z" fill="#000001"/>
        </svg>
        <a style="cursor:pointer" class="curr">{{$t('usefulInfo.article')}}</a>
  </Breadcrumbs>

   <div class="container">
    <div class="wrapp">
        <a class="view" @click="$router.push({ name: 'useful-info', params: {'lang': lang}})">
          <img src="/img/success-forward.svg" alt="arrow-img">
          <span>{{$t('usefulInfo.back')}}</span>
        </a>
      <h3 class="title">{{responceData.title}}</h3>
      <div class="wrap_preview" v-if="responceData.image != null">
        <img class="preview" :src="responceData.image" alt="">
      </div>
      <div class="wrapp_desc">
        <p class="desc" v-html="responceData.description"></p>
      </div>

    </div>

    <div class="media">
      <div class="card" v-for="(card, ind) in wrappResponceMedia" :key="ind" >
          <a :href="card.src" target="_blank">
        <div>
        <img :src="card.thumb" alt="">
        </div>
          </a>
      </div>
    </div>

  </div>
  
  <FooterApp/>
</div>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import { mapGetters } from "vuex"
import HeaderWrp from "@/components/HeaderWrp"
import FooterApp from "@/components/FooterApp"

const domain = process.env.VUE_APP_BASE_API;

export default {
  components: {
    Breadcrumbs,
    HeaderWrp,
    FooterApp,
  },
  async beforeMount(){
    this.getBlog();
  },
  data:()=>({
    responceData: {
      post_files:[],
      post_video_link: []
    },
    lightBox: [],
    indLightBox: null,
  }),
  watch:{
    lang(){
      this.getBlog();
    }
  },
  computed: {
    ...mapGetters({ 
      lang: 'languages/getLang',
      user: 'user/user'
    }),
    wrappResponceMedia(){
      let array = [];
      this.responceData.literature_files.forEach((el) => {
        array.push({
          id: el.id,
          title: el.title,
          description: el.description,
          thumb: el.image_preview,
          src: el[`file_${this.lang}`]
        });
      });
      this.responceData.literature_video_link.forEach(el => {
        array.push({
          id: el.id,
          title: el.title,
          description: el.description,
          thumb: el.image_preview,
          src: el.link
        });
      });
      return array;
    },
  },
  methods: {
    checkPdfLink(str){
      return str.substring(str.length - 3).toUpperCase() == "PDF";
    },
    async getBlog(){
      try {
        const result = await fetch(`${domain}/${this.lang}/literature/${this.$route.params.id}/?page_size=10000`, {
          headers: {
            Authorization: `Token ${this.user.token}`
          }
        });
        if(result.ok){
          const data = await result.json();
          this.responceData = data;
        }
        else {
          console.error(result);
          this.$router.push({name: 'Home', params: {lang: this.lang} });
        }
      

      }
      catch(err){
        console.error(err);
      }
    }
  }
}
</script>

<style lang="scss">
@media(min-width: 1024px){
  img.preview{
    width: 720px !important;
    height: 380px !important;
  }
}
  body.night {
    .use_full_info {
      .wrapp {
        .title {
          color: white;
        }
      }
      .wrapp_desc {
        .desc {
          color: white;
        }
      }
    }
  }
  .use_full_info {
    .container {
      padding: 0px 20px;
      @media(max-width: 430px) {
        padding: 0px 16px;
      }
    }
   .wrapp {
     max-width: 1200px;
     margin: auto;
     display: flex;
     flex-direction: column;
    .title {
      font-weight: 600;
      font-size: 40px;
      line-height: 55px;
      color: #000001;
      @media(max-width: 430px){
        font-size: 25px;
        line-height: 30px;
      }
    }
   }
    .wrap_preview {
      // height: 600px;
      height: 100%;
      border-radius: 12px;
      overflow: hidden;
      @media(max-width: 430px){
        height: 300px;
      }
      .preview {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        object-fit: cover;
        object-position: top;
        @media(min-width: 1200px){
          object-position: top left;
        }
      }
    }
    .wrapp_desc {
      margin-top: 25px;
      margin-bottom: 100px;
      .desc {
        font-weight: 500;
        line-height: 27px;
      }
    }
    .media {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 200px;
      .card {
        width: 350px;
        height: 250px;
        margin: 25px;
        cursor: pointer;
        border-radius: 18px / 28px;
        img {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
          border-radius: 18px / 28px;
        }
      }
    }

    .view {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      cursor: pointer;
      @media(max-width: 430px){
        display: none;
      }
      img {
        transform: rotate(180deg);
        margin-left: 20px;
        transition: 0.5s;
      }
      span {
        font-weight: 600;
        text-transform: uppercase;
        margin-left: 20px;
        color: #930684;
   
      }
      &:hover {
        img {
          margin-left: 10px;
        }
        span {
          color: #c00cae;
        }
      }
    }


  }

</style>
